import { createInjectable } from 'ngxtension/create-injectable';
import { type AbstractAuthService } from './abstract-auth.service';
import { inject, signal } from '@angular/core';
import { type TokenResponseType } from '@tig-dpqa-cloud/contract-backend-frontend';
import { map, of, tap } from 'rxjs';
import { getRandomString } from '../../util/get-random-string';
import { HttpClient } from '@angular/common/http';
import { SHA256, enc } from 'crypto-js';
import { StorageService } from '../storage/storage.service';
import { environment } from '../../../../environments/environment';
import { OAuthDiscoveryService } from '../oauth-discovery/oauth-discovery.service';

const getPcke = () => {
  const code_verifier = getRandomString(128);
  const challenge = SHA256(code_verifier).toString(enc.Base64url);

  return {
    challenge,
    verifier: code_verifier,
    method: 'S256',
  };
};

export const AuthService = createInjectable<() => AbstractAuthService>(
  () => {
    const http = inject(HttpClient);
    const oAuthDiscoveryService = inject(OAuthDiscoveryService);
    const storageService = inject(StorageService);

    const isAuthenticated = signal(!!storageService.get('token'));

    return {
      isAuthenticated: () => isAuthenticated(),
      getAccessToken: () => storageService.get<string>('token'),
      exchangeAccessToken: (code) =>
        http
          .post<TokenResponseType>('/api/token', { code, code_verifier: storageService.get<string>('code_verifier') })
          .pipe(
            map((res) => {
              storageService.remove('code_verifier');
              storageService.remove('state');

              storageService.set('token', res.token);
              storageService.set('refreshToken', res.refreshToken || '');

              isAuthenticated.set(true);
            }),
          ),
      refreshToken() {
        const refreshToken = storageService.get<string>('refreshToken');

        if (!refreshToken) {
          return of(this.authorize());
        }

        return http
          .post<TokenResponseType>('/api/token', { refresh_token: refreshToken })
          .pipe(map(({ token }) => storageService.set('token', token)));
      },
      logout: () => {
        const token = storageService.get<string>('token');

        storageService.remove('token');
        storageService.remove('refreshToken');

        oAuthDiscoveryService
          .getLogoutUri()
          .pipe(
            tap((uri) => {
              const url = new URL(uri);

              const params: Record<string, string> = {
                id_token_hint: token!,
                post_logout_redirect_uri: window.location.origin,
              };

              Object.keys(params).forEach((key) => url.searchParams.set(key, params[key]));

              window.open(url, '_self');
            }),
          )
          .subscribe();
      },
      authorize: () => {
        isAuthenticated.set(false);
        const pcke = getPcke();

        storageService.set('code_verifier', pcke.verifier);
        if (!storageService.get('redirect_uri')) { storageService.set('redirect_uri', window.location.pathname); }

        const state = getRandomString(32);
        storageService.set('state', state);

        oAuthDiscoveryService
          .getAuthorizationUri()
          .pipe(
            tap((authorizeUri) => {
              const url = new URL(authorizeUri);

              const params: Record<string, string> = {
                scope: environment.oauth_scope,
                response_type: 'code',
                client_id: environment.oauth_client_id,
                redirect_uri: `${window.location.origin}/callback`,
                code_challenge_method: pcke.method,
                code_challenge: pcke.challenge,
                state,
              };

              Object.keys(params).forEach((key) => url.searchParams.set(key, params[key]));

              window.open(url, '_self');
            }),
          )
          .subscribe();
      },
    };
  },
{ providedIn: 'root' },
);
